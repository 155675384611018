import { apiRequest } from '@glow/shared/lib/http';
import { useQuery } from '@tanstack/vue-query';
import { cityKeys } from './keys';
import type { City } from './types';

export type CityDetectionRequest = {
  address?: string;
};

export function detectCity(request: CityDetectionRequest = {}) {
  return apiRequest<City>('get', '/cities/detected', request);
}

export function useDetectCity(
  request: MaybeRef<CityDetectionRequest> = {},
  enabled: MaybeRef<boolean> = true
) {
  return useQuery(cityKeys.detected(), () => detectCity(unref(request)), {
    enabled,
  });
}
